/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    .section h2 {
        background-color: var(--white);
        color: var(--app-blue);
        border: 3px solid var(--dark-green);
        text-align: center;
        padding: 15px;
        border-radius: 10px;
    }

    .wrapper {
        background-color: var(--white);
        padding: 6px;
        border: 3px solid var(--dark-green);
        border-radius: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .section p {
        color: var(--dark-text);
        text-align: center;
        font-size: 16px;
    }
}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {
    .section h2 {
        background-color: var(--white);
        color: var(--app-blue);
        border: 3px solid var(--dark-green);
        text-align: center;
        padding: 15px;
        border-radius: 10px;
    }

    .wrapper {
        background-color: var(--white);
        padding: 6px;
        border: 3px solid var(--dark-green);
        border-radius: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .section p {
        color: var(--dark-text);
        text-align: center;
        font-size: 16px;
    }
}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {
    .section h2 {
        color: var(--app-blue);
        border-bottom: 3px solid var(--dark-green);
    }

    .section p {
        color: var(--dark-text);
    }

    .section a, .section a:visited {
        color: var(--app-blue);
        text-decoration: none;
    }

    .section a:hover, a:target {
        color: var(--app-blue);
        text-decoration: underline;
    }
}