/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {

    body.landing-page {
        background: url('../../img/background-header-home.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {

    body.landing-page {
        background: url('../../img/background-header-home.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

}