@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
}

/*html {*/
/*  height: 100%;*/
/*}*/

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  /*min-height: 100%;*/
}

.text-center {
  text-align: center;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

body.whiteBackground  {
  background-color: var(--white);
}

.breadcrumb-separator {
    display: inline-block;
    margin: 0 0.5em;
    color: var(--breadcrumb-gray);
}

.grayText {
    color: var(--breadcrumb-gray);
}

.input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input2 {
    width: 80%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}

.confirmBtn {
    background-color: cornflowerblue;
}

.cancelBtn {
    background-color: crimson;
}

.footer-m {
    display: flex;
    justify-content: space-between;
}

.form-footer {
    display: flex;
    justify-content: space-between;
}

.breadCrumbLink {
    color: var(--breadcrumb-blue);
    text-decoration: none;
}

.breadCrumbLink:hover {
    color: var(--breadcrumb-blue);
    text-decoration: underline;
}

.breadCrumbLink:visited {
    color: var(--breadcrumb-blue);
}

.breadcrumbBorderBottom {
    border-bottom: 1px solid var(--border-gray);
    margin: 30px 0;
}

.visibleHeader {
    display: inline-block;
    text-align: center;
    padding: 14px;
    color: var(--white);
    background-color: var(--app-blue);
    border-radius: 20px;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 150px;
}

.boxButton {
    background-color: var(--app-blue);
    color: var(--white);
    padding: 15px;
    border-radius: 30px;
    text-decoration: none;
}

.boxButton:hover {
    text-decoration: underline;
}

.mt25 {
    margin-top: 25px;
}

.min-height-443px {
    min-height: 443px;
}



