.report {
    background-color: var(--white);
    height: auto;
    border-radius: 15px;
    padding: 16px;
}



.button {
    color: var(--white);
    background-color: var(--app-blue);
    padding: 20px;
    border-radius: 10px;
    margin: 15px auto;
    display: inline-block;
    text-decoration: none;
}

.button:hover {
    text-decoration: underline;
}

.button:visited {
    color: var(--white);
    background-color: var(--app-blue);
}

.image {
    display: block;
    margin: 15px auto;
}

.container {
    text-align: center;
}

