/*******************************************************************************************
 * Navbar - Public - Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    .desktopLogo, .tabletLogo, .loginDesktop, .registerDesktop {
        display: none;
    }

    .logo {
        height: 30px;
    }

    .navLogin a, .navRegister a {
        font-size: 0;
    }

    .flexContainerHamburger {
        display: flex;
        justify-content: space-between;
    }

    .navLogin {
        padding-left: 2em;
    }

    .navRegister {
        margin-left: auto;
    }

    .navWrapper {
        background-color: var(--white);
        height: 60px;
    }

    .navWrapperProtected {
        height: 100px;
    }

    .navPub {
        background-color: inherit;
        height: inherit;
        width: 90%;
        margin: 0 auto;
    }

    .navContainer {
        margin: 0;
        height: inherit;
        list-style: none;
        display: flex;
        align-items: center;
        padding-left: 0;
    }
    .protectedNavItem a {
        color: var(--navbar-black);
        text-transform: uppercase;
        text-decoration: none;
        margin-right: auto;
    }
    
    .protectedNavItem a:hover {
        color: var(--navbar-black);
        text-decoration: underline;
    }
    
    .protectedNavItem a:visited {
        color: var(--navbar-black);
    }
    
    .ulLogoProtected {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        padding-top: 15px;
    }
    
    .ulLogoProtected li {
        list-style: none;
        margin-top: 0;
    }

    .hamburgerMenu {
        list-style: none;
    }

    .sideNav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: #ccc;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
    }

    .sideNav li {
        list-style-image: url('../../../img/li-image.png');
        line-height: 40px;
    }
}

/*******************************************************************************************
 * Navbar - Public - Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {
    .mobileLogo, .desktopLogo, .loginDesktop, .registerDesktop {
        display: none;
    }

    .logo {
        height: 40px;
    }

    .hamburgerMenu {
        list-style: none;
    }

    .navLogin a, .navRegister a {
        font-size: 0;
    }

    .navLogin {
        padding-left: 2em;
    }

    .navRegister {
        margin-left: auto;
    }

    .hamburgerIcon {
        margin-left: auto;
    }

    .navContainer {
        margin: 0;
        height: inherit;
        list-style: none;
        display: flex;
        align-items: center;
    }

    .navWrapper {
        background-color: var(--white);
        height: 60px;
    }

    .navPub {
        background-color: inherit;
        height: inherit;
        width: 90%;
        margin: 0 auto;
    }
  .protectedNavItem a {
      color: var(--navbar-black);
      text-transform: uppercase;
      text-decoration: none;
      margin-right: auto;
  }

  .protectedNavItem a:hover {
      color: var(--navbar-black);
      text-decoration: underline;
  }

  .protectedNavItem a:visited {
      color: var(--navbar-black);
  }

  .ulLogoProtected {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-top: 15px;
  }

  .ulLogoProtected li {
      list-style: none;
      margin-top: 0;
  }

  .sideNav {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      background-color: #ccc;
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 60px;
  }
}

/*******************************************************************************************
 * Navbar - Public - Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {

    .navWrapper {
        background-color: var(--white);
        height: 90px;
    }

    .logo {
        height: 40px;
    }

    .navWrapperProtected {
        background-color: var(--white);
        height: 120px;
    }

    .hamburgerMenu {
        display: none;
    }

    .navPub {
        height: inherit;
        background-color: inherit;
        width: 80%;
        max-width: 1362px;
        margin: 0 auto;
    }

    .hamburgerIcon {
        display: none;
    }

    .navContainer {
        margin: 0;
        height: inherit;
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
    }

    .navContainerProtected {
        display: flex;
        height: 100%;
        justify-content: space-between;
        margin-top: 20px;
        list-style: none;
        padding-left: 0;
    }

    .navLogin {
        padding-left: 1em;
    }

    .navRegister {
        margin-left: auto;
    }

    .mobileLogo, .loginMobile, .registerMobile, .tabletLogo {
        display: none;
    }

    .desktopLogo {
        display: flex;
    }

    .desktopLogoProtected {
        margin-top: -50px;
    }

    .navLogin a, .navLogin a:visited, .navRegister a, .navRegister a:visited {
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--nav-pub-brown);
    }

    .navLogin img, .navRegister img {
        margin-right: 10px;
    }

    .protectedNavItem a {
        color: var(--navbar-black);
        text-transform: uppercase;
        text-decoration: none;
        margin-right: auto;
    }

    .protectedNavItem a:hover {
        color: var(--navbar-black);
        text-decoration: underline;
    }

    .protectedNavItem a:visited {
        color: var(--navbar-black);
    }

    .ulLogoProtected {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        padding-top: 15px;
    }

    .ulLogoProtected li {
        list-style: none;
        margin-top: 0;
    }
}

