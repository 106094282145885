/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    body.login-page {
        background: url('../../img/background-image-login.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {

    body.login-page {
        background: url('../../img/background-image-login.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {

    body.login-page {
        background: url('../../img/background-image-login.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

}