.title {
    color: var(--meta-black);
    margin-bottom: 10px;
}

.content {
    color: var(--meta-blue);
    margin-left: 22px;
    margin-bottom: 20px;
}

.spacing {
    margin-left: 5px;
}

.deleteIcon {
    margin-right: 5px;
}

.editIcon {
    margin-right: 5px;
}
