/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    .loginBox {
        background-color: var(--white);
        width: 100%;
        padding: 40px 20px;
        margin: 40px auto;
        height: 550px;
        border-radius: 16px;
        align-self: center;
    }

}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {
    .loginSection {
        width: 100%;
        min-height: inherit;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }


    .loginBox {
        background-color: var(--white);
        width: 40%;
        padding: 40px 20px;
        height: 550px;
        border-radius: 16px;
    }

}





