.primary {
    background-color: var(--app-blue);
    color: var(--white);
}

.danger {
    background-color: var(--danger-red-background);
    color: var(--danger-red-foreground);
}

.warning {
    background-color: var(--warning-alert-background);
    color: var(--warning-alert-foreground);
}

.success {
    background-color: var(--alert-success-background);
    color: var(--alert-success-foreground);
}

.alert {
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    margin: 15px 0;
}

