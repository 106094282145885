.grayText {
    color: var(--breadcrumb-gray);
}

.fullHeight {
    height: 100%;
}

.flexBoxContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
}