:root {
    --white: #fff;
    --app-blue: #117aff;
    --nav-pub-brown: #4d4c4c;
    --light-green: #11ea7c;
    --dark-green: #0a6a82;
    --dark-text: #4c4c4c;
    --light-gray: #f3f3f5;
    --black-text: #404241;
    --dark-blue: #00539e;
    --pink: #fee5e4;
    --breadcrumb-gray: #828282;
    --breadcrumb-blue: #0c61fe;
    --border-gray: #a5a5a5;
    --danger-red-background: #f4cdd1;
    --danger-red-foreground: #cb0014;
    --blue-resource-box: #d4e8ff;
    --button-green: #259b34;
    --cool-black: #292c31;
    --option-gray: #6e6e6e;
    --meta-blue: #3773ff;
    --meta-black: #131316;
    --warning-alert-background: #fff1c2;
    --warning-alert-foreground: #d7a92f;
    --alert-success-background: #cbead1;
    --alert-success-foreground: #42a850;
    --navbar-black: #202020;
}