/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    .spreadSheet {
        overflow-x: scroll !important;
        width: 100%;
        position: fixed;
    }
}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {
    .spreadSheet {
        overflow-x: auto;
        margin: 0 auto;
        width: 100%;
    }

}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {
    .spreadSheet {
        overflow-x: auto;
        margin: 0 auto;
    }
}