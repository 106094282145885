.flexBoxContainer {
    display: flex;
    justify-content: space-between;
}

/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media screen and (max-width: 767px) {
    .flexBoxContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}