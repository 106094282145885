/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    .whiteBackground {
        background-color: white;
        padding: 10px 20px;
        border-radius: 8px;
        min-height: 680px;
        width: 90vw;
        margin: 40px auto 60px auto;
    }
}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {
    .whiteBackground {
        background-color: white;
        padding: 20px 60px;
        border-radius: 8px;
        min-height: 680px;
        width: 90vw;
        margin: 40px auto 60px auto;
    }
}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {
    .whiteBackground {
        background-color: white;
        padding: 20px 60px;
        border-radius: 8px;
        min-height: 680px;
        width: 90vw;
        margin: 40px auto 60px auto;
    }
}
