.breadCrumbLink {
    color: var(--breadcrumb-blue);
    text-decoration: none;
}

.breadCrumbLink:hover {
    color: var(--breadcrumb-blue);
    text-decoration: underline;
}

.breadCrumbLink:visited {
    color: var(--breadcrumb-blue);
}

.whiteBackground {
    background-color: white;
    padding: 20px 60px;
    border-radius: 8px;
    min-height: 680px;
    width: 100%;
    margin: 40px auto 60px auto;
}

.breadcrumbBorderBottom {
    border-bottom: 1px solid var(--border-gray);
    margin: 30px 0;
}

.greenButton {
    background-color: var(--button-green);
    border-radius: 8px;
    border: none;
    color: var(--white);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}