/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/
@media all and (max-width: 767px) {

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--light-gray);
        width: 100%;
        padding: 0 20px;
        border-radius: 16px;
        height: 500px;
        overflow: hidden;
        border: 2px solid var(--black-text);
    }

    .flexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: inherit;
        height: auto;
        padding: 20px 0;
    }

    .form {
        width: 100%;
    }

    .emailInput {
        display: block;
        width: 100%;
        border-radius: 15px;
        height: 60px;
    }

    .header {
        color: var(--black-text);
        text-align: center;
    }

    .button {
        background-color: var(--dark-blue);
        color: var(--white);
        padding: 20px;
        border-radius: 20px;
        text-transform: uppercase;
        display: block;
        margin: 20px auto;
        width: 48%;
    }

    .link {
        text-decoration: none;
        color: var(--app-blue);
    }

    .link:hover {
        text-decoration: underline;
    }

    .link:visited {
        color: var(--app-blue);
    }

    .centerLink {
        text-align: center;
        margin-top: 20px;
    }

    .blackText {
        color: var(--black-text);
        text-align: center;
    }

}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/
@media all and (min-width: 768px) and (max-width: 1199px) {


}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--light-gray);
        width: 35%;
        padding: 0 20px;
        border-radius: 16px;
        height: 80%;
        max-height: 500px;
        overflow: hidden;
        border: 2px solid var(--black-text);
    }

    .flexContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: calc(100vh - 180px);
        min-height: inherit;
    }

    .form {
        width: 100%;
    }

    .emailInput {
        display: block;
        width: 100%;
        border-radius: 15px;
        height: 60px;
    }

    .header {
        color: var(--black-text);
        text-align: center;
    }

    .button {
        background-color: var(--dark-blue);
        color: var(--white);
        padding: 20px;
        border-radius: 20px;
        text-transform: uppercase;
        display: block;
        margin: 20px auto;
        width: 48%;
    }

    .link {
        text-decoration: none;
        color: var(--app-blue);
    }

    .link:hover {
        text-decoration: underline;
    }

    .link:visited {
        color: var(--app-blue);
    }

    .centerLink {
        text-align: center;
        margin-top: 20px;
    }

    .blackText {
        color: var(--black-text);
        text-align: center;
    }

}

