.heading {
    color: var(--white);
    text-align: center;
}
@media all and (min-width: 1200px) {
    .gridContainer {
            display: grid;
            grid-template-columns: repeat(2, 40%);
            justify-content: space-evenly;
            min-height: inherit;
            align-items: center;
        }
}


@media all and (min-width: 768px) and (max-width: 1199px) {
    .gridContainer {
            display: grid;
            grid-template-columns: repeat(2, 30%);
            grid-column-gap: 10%;
            justify-content: space-evenly;
            min-height: inherit;
            align-items: center;
        }
}

@media all and (max-width: 767px) {
    .gridContainer {
            display: grid;
            grid-template-columns: repeat(1, 60%);
            grid-column-gap: 10%;
            justify-content: space-evenly;
            min-height: inherit;
            align-items: center;
            grid-row-gap: 10%;
        }
}