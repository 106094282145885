/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    .phoneInput {
        border: 3px solid var(--app-blue);
        display: block;
        padding: 20px;
        width: 100%;
        margin: 0;
        border-radius: 15px
    }

    .blueBtnLoginForm {
        text-transform: uppercase;
        background-color: var(--app-blue);
        color: var(--white);
        padding: 15px;
        border-radius: 16px;
        width: 200px;
        display: inline-block;
        text-align: center;
    }

    .errorMessage {
        color: red;
        margin: 0;
        padding: 15px;
    }
}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {
    .phoneInput {
        border: 3px solid var(--app-blue);
        display: block;
        padding: 20px;
        width: 100%;
        margin: 0;
        border-radius: 15px
    }

    .blueBtnLoginForm {
        background-color: var(--app-blue);
        color: var(--white);
        padding: 15px;
        border-radius: 16px;
        width: 120px;
        display: inline-block;
        text-align: center;
    }

    .errorMessage {
        color: red;
        margin: 0;
        padding: 15px;
    }
}

