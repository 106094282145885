
/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    .footerWrapper {
        width: 100%;
        height: 115px;
        background-color: var(--app-blue);
        position: relative;
        top: 15vh;
        margin-top: 25px;
    }

    .footer {
        width: 90%;
        height: inherit;
        font-size: 15px;
        background-color: inherit;
        color: var(--white);
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .footerDevelopment {
        display: none;
    }

    .navIcons {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 0;
    }

    .navIcons li {
        list-style: none;
    }

    .img {
        height: 25px;
    }

    .footerExternalLinks {
        display: flex;
        justify-content: space-evenly;
        padding: 0;
    }

    .footerExternalLinks li {
        list-style: none;
    }

    .footerExternalLinks li a {
        font-size: 16px;
        color: var(--white);
        text-decoration: none;
    }

    .footerCopyright {
        display: none;
    }
}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {
    .footerWrapper {
        width: 100%;
        height: 120px;
        background-color: var(--app-blue);
        position: relative;
        top: 15vh;
        margin-top: 25px;
    }

    .footer {
        width: 90%;
        height: inherit;
        font-size: 15px;
        background-color: inherit;
        color: var(--white);
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .footerDevelopment {
        display: none;
    }

    .navIcons {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 0;
    }

    .navIcons li {
        list-style: none;
    }

    .img {
        height: 25px;
    }

    .footerExternalLinks {
        display: flex;
        justify-content: space-around;
        padding: 0;
        height: inherit;
    }

    .flexBasis {
        flex-basis: 33.33333%
    }

    .center {
        margin: 0 auto;
    }


    .footerExternalLinks li {
        list-style: none;
    }

    .footerExternalLinks li a {
        font-size: 16px;
        color: var(--white);
        text-decoration: none;
    }

    .footerCopyright {
        display: none;
    }
}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {

    .footerWrapper {
        width: 100%;
        height: 90px;
        background-color: var(--app-blue);
        margin-top: 25px;
    }

    .footer {
        width: 80%;
        max-width: 1362px;
        height: inherit;
        font-size: 15px;
        background-color: inherit;
        color: var(--white);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
    }

    .footerExternalLinks {
        list-style: none;
        padding-left: 0;
        display: flex;
        justify-content: space-between;
        flex-basis: 25%;
    }

    .flexBasis {
        flex-basis: 33.33333%
    }

    .center {
        margin: 0 auto;
    }

    .footer ul li a, .flexboxContainer a {
        color: var(--white);
        text-decoration: none;
    }

    .footer ul li a:hover, .flexboxContainer a:hover {
        text-decoration: underline;
    }

    .footer p span {
        color: var(--light-green);
    }

    .footer p a {
        color: #ddb55a;
        text-decoration: none;
    }

    .footer p a:hover {
        text-decoration: underline;
    }

    .footer p a:hover {
        color: #ddb55a;
    }

    .nav {
        display: none;
    }

    .flexboxContainer {
        display: flex;
        flex-direction: row;
        height: inherit;
        justify-content: space-evenly;
        width: 200px;
    }
}