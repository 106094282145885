.flexContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: inherit;
}

.box {
    padding: 16px;
    border-radius: 30px;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 150px;
}

.minHeight {
    min-height: 420px;
}

.button {
    background-color: var(--app-blue);
    color: var(--white);
    padding: 15px;
    border-radius: 30px;
    text-decoration: none;
}

.button:hover {
    text-decoration: underline;
}

.flexContainerVerified {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: inherit;
}

.mt25 {
    margin-top: 25px;
}

@media all and (min-width: 1200px) {
    .mb25Desktop {
        margin-bottom: 25px;
    }
}

.visibleHeader {
    display: inline-block;
    text-align: center;
    padding: 14px;
    color: var(--white);
    background-color: var(--app-blue);
    border-radius: 20px;
}

@media all and (min-width: 768px) and (max-width: 1199px) {
    .flexContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: inherit;
    }
    .box {
        /*height: auto;*/
        /*width: auto;*/
        border-radius: 30px;
    }
}

@media all and (max-width: 767px) {
    .flexContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: inherit;
    }

    .box {
        /*height: auto;*/
        /*width: auto;*/
        border-radius: 30px;
    }
}