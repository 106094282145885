@media all and (min-width: 1200px) {
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        justify-content: space-evenly;
        min-height: inherit;
        align-items: flex-start;
        grid-auto-rows: 1fr;
        grid-row-gap: 30px;
    }
}

@media all and (min-width: 768px) and (max-width: 1199px) {
    .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;
            justify-content: space-evenly;
            min-height: inherit;
            align-items: flex-start;
            grid-auto-rows: 1fr;
            grid-row-gap: 30px;
        }
}

@media all and (max-width: 767px) { 
    .grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 30px;
            justify-content: space-evenly;
            min-height: inherit;
            align-items: flex-start;
            grid-auto-rows: 1fr;
            grid-row-gap: 30px;
        }
}