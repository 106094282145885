.heading {
    color: white;
    text-align: center;
    margin: 0;
}

.visibleHeader {
    display: inline-block;
    text-align: center;
    padding: 14px;
    color: var(--white);
    background-color: var(--app-blue);
    border-radius: 20px;
}

@media all and (min-width: 1200px) {
    .gridContainer {
        display: grid;
        grid-column-gap: 5%;
        grid-template-columns: repeat(3, 33%);
        justify-content: space-evenly;
        min-height: 450px;
        align-items: center;
    }
}


@media all and (min-width: 768px) and (max-width: 1199px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(2, 33%);
        grid-column-gap: 10%;
        justify-content: space-evenly;
        min-height: 450px;
        align-items: center;
        grid-row-gap: 10%;
    }
}

@media all and (max-width: 767px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(1, 66%);
        grid-column-gap: 10%;
        justify-content: space-evenly;
        min-height: inherit;
        align-items: center;
        grid-row-gap: 5%;
    }
}