.section {
    padding-top: 20px;
    padding-bottom: 20px;
}

.flexContainer {
    padding-top: 20px;
}

/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {

    .header2 {
        display: inline-block;
        text-align: center;
        padding: 20px;
        background-color: var(--white);
        color: var(--app-blue);
        border: 3px solid var(--app-blue);
        border-radius: 4px;
    }

}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {
    .header2 {
        display: inline-block;
        text-align: center;
        padding: 20px;
        background-color: var(--white);
        color: var(--app-blue);
        border: 3px solid var(--app-blue);
        border-radius: 6px;
    }

    .flexContainer {
        display: flex;
        justify-content: space-evenly;
        width: inherit;
    }
}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {
    .header2 {
        display: inline-block;
        text-align: center;
        padding: 15px;
        color: var(--app-blue);
        border: 5px solid var(--app-blue);
        border-radius: 20px;
    }

    .flexContainer {
        display: flex;
        justify-content: space-evenly;
    }

}
