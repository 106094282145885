/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    .landingPageHeader {
        display: inline-block;
        text-align: center;
        padding: 10px;
        color: var(--white);
        background-color: var(--app-blue);
        border-radius: 20px;
    }

    .flexContainer {
        display: flex;
        flex-direction: column-reverse;
    }

    .flexContainer img {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .appDescription {
        background-color: var(--app-blue);
        border-radius: 10px;
        padding: 10px;
        color: var(--white);
        text-align: center;
        font-size: 16px;
    }

    .headerLanding {
        padding-top: 20px;
    }
}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {
    .landingPageHeader {
        display: inline-block;
        text-align: center;
        padding: 10px;
        color: var(--white);
        background-color: var(--app-blue);
        border-radius: 20px;
    }

    .flexContainer {
        display: flex;
        align-items: center;
    }

    .appDescription {
        background-color: var(--app-blue);
        border-radius: 10px;
        padding: 10px;
        color: var(--white);
        text-align: center;
    }

    .headerLanding {
        padding-top: 20px;
    }
}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {
    .headerLanding {
        padding-top: 20px;
        background-image: url('../../img/background-header-home.png');
        background-repeat: no-repeat no-repeat;
        width: 100%;
        height: 540px;
        display: flex;
        flex-direction: column;
    }

    .landingPageHeader {
        display: inline-block;
        text-align: center;
        padding: 14px;
        color: var(--white);
        background-color: var(--app-blue);
        border-radius: 20px;
    }

    .flexContainer {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .appDescription {
        background-color: var(--app-blue);
        border-radius: 20px;
        padding: 15px;
        color: var(--white);
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 30%;
    }

    .flexContainer img {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: max-content;
    }
}