/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/
@media all and (max-width: 767px) {
    .container {
        width: 90%;
        margin: 0 auto;
        min-height: calc(100vh - 215px);
        height: auto;
    }
}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/
@media all and (min-width: 768px) and (max-width: 1199px) {
    .container {
        width: 90%;
        margin: 0 auto;
        min-height: calc(100vh - 210px);
        height: auto;
    }
}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/
@media all and (min-width: 1200px) {
    .container {
        width: 80%;
        max-width: 1362px;
        margin: 0 auto;
        min-height: calc(100vh - 180px);
        height: auto;
    }

    .protectedContainer {
        width: 80%;
        max-width: 1362px;
        margin: 0 auto;
        min-height: calc(100vh - 210px);
        height: auto;
    }
}

