/*******************************************************************************************
 * All
 ******************************************************************************************/

.img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.blueButton {
    background-color: var(--app-blue);
    color: var(--white);
    text-decoration: none;
    padding: 15px;
    border-radius: 30px;
    text-transform: uppercase;
}

.blueButton:visited {
    background-color: var(--app-blue);
    color: var(--white);
    text-decoration: none;
    padding: 15px;
    border-radius: 30px;
    text-transform: uppercase;
}

.completeCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {

    .img {
        width: 80%;
    }

}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {



}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {



}

