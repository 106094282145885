
.button {
    color: var(--white);
    background-color: var(--app-blue);
    padding: 20px;
    border-radius: 10px;
    margin: 15px auto;
    display: inline-block;
    text-decoration: none;
}

.button:hover {
    background-color: var(--app-blue);
    color: var(--white);
    text-decoration: underline;
}

.button:visited {
    background-color: var(--app-blue);
    color: var(--white);
}

.img {
    display: block;
    margin: 0 auto;
}

.permit {
    height: auto;
}

