.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 990;
}

.modalContainer {
    width: 450px;
    min-height: 500px;
    height: 80vh;
    overflow-y: auto;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    background-color: #f2f2f2;
    overflow: auto;
    position: absolute;
    top: 10%;
}

/* mobile screen */
@media all and (max-width: 767px) {
    .modalContainer {
        width: 350px;
        min-height: 300px
    }
}




.modalContainer .title {
    display: inline-block;
    text-align: left;
    margin-top: 10px;
}

.titleCloseBtn {
    display: flex;
    justify-content: space-between;
}

.titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.modalContainer .body {
    background-color: #f2f2f2;
}

.modalContainer .footer {
    display: flex;
    justify-content: space-between;
}

.btn {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}

.confirmBtn {
    background-color: cornflowerblue;
}

.cancelBtn {
    background-color: crimson;
}
