.flexContainer {
    display: flex;
    justify-content: space-between;
}

.flexItem:nth-child(2) {
    align-self: center;
}

.border {
    border-bottom: 1px solid var(--border-gray);
    margin: 30px 0;
}

.breadCrumbLink {
    color: var(--breadcrumb-blue);
    text-decoration: none;
}

.breadCrumbLink:hover {
    color: var(--breadcrumb-blue);
    text-decoration: underline;
}

.breadCrumbLink:visited {
    color: var(--breadcrumb-blue);
}