/*******************************************************************************************
 * Mobile Screens
 ******************************************************************************************/

@media all and (max-width: 767px) {
    .flexItem {
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border: 3px solid var(--app-blue);
        border-radius: 8px;
        margin-bottom: 35px;
    }

    .flexContainer {
        display: flex;
        justify-content: center;
        margin: 30px 0;
    }
    

    .header {
        display: inline-block;
        text-align: center;
        color: var(--app-blue);
        text-transform: uppercase;
        border-bottom: 3px solid var(--dark-green);
        padding-top: 20px;
    }

    .features li {
        padding: 15px 0;
        color: var(--dark-text);
        font-size: 15px;
    }
}

/*******************************************************************************************
 * Tablet Screens
 ******************************************************************************************/

@media all and (min-width: 768px) and (max-width: 1199px) {

    .flexItem {
        border: 5px solid var(--app-blue);
        border-radius: 15px;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        flex-basis: 40%;
    }

    .features li {
        padding: 15px 0;
        color: var(--dark-text);
        font-size: 15px;
    }

    .header {
        display: inline-block;
        text-align: center;
        color: var(--app-blue);
        text-transform: uppercase;
        border-bottom: 3px solid var(--dark-green);
        padding-top: 20px;
    }

    .flexContainer {
        margin: 15px auto;
        display: flex;
        justify-content: space-evenly;
    }
}

/*******************************************************************************************
 * Desktop Screens
 ******************************************************************************************/

@media all and (min-width: 1200px) {

    .header {
        display: inline-block;
        text-align: center;
        color: var(--app-blue);
        text-transform: uppercase;
        border-bottom: 3px solid var(--dark-green);
        padding-top: 20px;
    }

    .flexItem {
        border: 5px solid var(--app-blue);
        border-radius: 15px;
        flex: 0 0 40%;
    }

    .flexContainer {
        margin: 15px auto;
        display: flex;
        justify-content: space-evenly;
    }

    .responsive img {
        width: 95%
    }

    .features {
        margin-left: 30px;
        margin-right: 30px;
    }

    .features li {
        padding: 20px 0;
        color: var(--dark-text);
    }

   
}