.centerText {
    text-align: center;
}

.marginTop {
    margin-top: 15px;
}

.flexContainer {
    margin-top: 15px;
    display: flex;
    justify-content: space-evenly;
}

.listStyle {
    list-style: none;
    padding-bottom: 15px;
    margin: 0;
}

.routeIcon {
    margin-right: 15px;
}

.emailIcon {
    margin-right: 8px;
}

.nowrap {
    white-space: nowrap;
}

.fullHeight {
    height: 100%;
}

.flexContainerColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
}