.button {
    background-color: var(--button-green);
    border-radius: 8px;
    border: none;
    color: var(--white);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.select {
    color: var(--option-gray);
    display: block;
    margin-right: 0;
    width: 100%;
    margin-bottom: 15px;
}

.month {
    display: block;
    color: var(--option-gray);
    margin-bottom: 15px;
}

.label {
    color: var(--cool-black);
    display: block;
}

.setWidth {
    flex-basis: 40%;
}

.buttonContainer {
    display: flex;
}